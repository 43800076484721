import React from "react"
import { graphql } from "gatsby"
import Layout from "src/components/layout"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const locale = post.frontmatter.country
  const country =
    post.frontmatter.country === "cy_en" ? "cy" : post.frontmatter.country
  const hotLine = data.site.siteMetadata.countries[country]["hotLine"]

  return (
    <Layout country={locale} hotLine={hotLine} countrySwitchOff={true}>
      <div>
        <h1>{post.frontmatter.title}</h1>
        <small>{post.frontmatter.date}</small>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    site {
      siteMetadata {
        senderPath
        countries {
          am {
            hotLine {
              tel
              title
            }
          }
          by {
            hotLine {
              tel
              title
            }
          }
          cy {
            hotLine {
              tel
              title
            }
          }
          en {
            hotLine {
              tel
              title
            }
          }
          lt {
            hotLine {
              tel
              title
            }
          }
          ru {
            hotLine {
              tel
              title
            }
          }
          ua {
            hotLine {
              tel
              title
            }
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        country
      }
    }
  }
`
